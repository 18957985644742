import { Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { HotkeysShortcutPipe } from '../../pipes/hotkeys-shortcut.pipe';
import { HotkeyGroup, HotkeysService } from '../../services/hotkeys.service';

@Component({
  templateUrl: './hotkeys-help.component.html',
  styleUrls: ['./hotkeys-help.component.scss'],
  standalone: true,
  imports: [NzTableModule, HotkeysShortcutPipe, NzEmptyModule, NzTypographyModule]
})
export class HotkeysHelpComponent implements OnInit {
  @Output() readonly dismiss = new EventEmitter<void>();

  hotkeys = signal<HotkeyGroup[]>([]);

  constructor(private hotkeysService: HotkeysService) {}

  ngOnInit() {
    this.hotkeys.set(this.hotkeysService.getShortcuts());
  }

  handleDismiss() {
    this.dismiss.next();
  }
}
