import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { hostPlatform } from '../utils/platform';

@Pipe({
  name: 'hotkey',
  standalone: true
})
export class HotkeysShortcutPipe implements PipeTransform {
  private readonly symbols;
  constructor(private translateService: TranslateService) {
    const platform = hostPlatform();

    // see http://xahlee.info/comp/unicode_computing_symbols.html for conventions:
    const symbols = {
      tab: '⇥',
      space: '␣'
    };

    const appleSymbols = {
      meta: '⌘',
      altleft: '⌥',
      control: '⌃',
      enter: '↩',
      escape: 'esc',
      arrowleft: '◀',
      arrowright: '▶',
      arrowup: '▲',
      arrowdown: '▼',
      shift: 'shift', // ⇧
      backspace: '⌫'
    };

    const pcSymbols = {
      control: this.translateService.instant('HOTKEYS.control'),
      altleft: 'Alt',
      enter: '↵',
      escape: 'Esc',
      arrowleft: '←',
      arrowright: '→',
      arrowup: '↑',
      arrowdown: '↓',
      shift: 'Shift', // ⇧
      backspace: 'Backspace' // ⟵
    };

    this.symbols = this.getPlatformSymbols(platform, symbols, appleSymbols, pcSymbols);
  }

  transform(value: string, dotSeparator = ' + ', thenSeparator = ' then '): any {
    if (!value) {
      return '';
    }
    return value
      .split('>')
      .map(s =>
        s
          .split(/[.+]/)
          .map(c => c.toLowerCase())
          .map(c => this.symbols[c] || c)
          .map(c => `<kbd>${c}</kbd>`)
          .join(dotSeparator)
      )
      .join(thenSeparator);
  }

  private getPlatformSymbols(platform: string, symbols: any, appleSymbols: any, pcSymbols: any): any {
    return platform === 'apple' ? { ...symbols, ...appleSymbols } : { ...symbols, ...pcSymbols };
  }
}
