@if (hotkeys(); as hotkeys) {
  @if (hotkeys.length === 0 || (hotkeys.length === 1 && hotkeys.at(0)?.hotkeys?.length === 0)) {
    <nz-empty />
  }
  @for (hotkeyGroup of hotkeys; track hotkeyGroup) {
    <div>
      @if (hotkeyGroup.group) {
        <h3>{{ hotkeyGroup.group }}</h3>
      }
      <nz-table #basicTable [nzData]="hotkeyGroup.hotkeys" [nzShowPagination]="false">
        <tbody>
          @for (data of basicTable.data; track data) {
            <tr>
              <td>{{ data.description }}</td>
              <td [style.textAlign]="'right'" [style.textWrap]="'nowrap'">
                <span nz-typography [innerHTML]="data.keys | hotkey"></span>
              </td>
            </tr>
          }
        </tbody>
      </nz-table>
    </div>
  }
}
